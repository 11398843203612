module.exports = {
  pathPrefix: `/kelseyscabin`,
  siteMetadata: {
    title: "Kelsey's Cabin - Northern Wiscosnin Vacation Rental",
    description: "We are located on the shores of crystal clear Crane Lake which is one of the natural lakes that now forms the Chippewa Flowage. Our 3 bedroom lakeside unit is your gateway to all of the finest recreational activites that the Hayward area has to offer.",
    siteURL: 'https://kelseyscabin.com',
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#663399',
        theme_color: '#663399',
        display: 'minimal-ui',
        icon: 'src/assets/images/favicon.png', // This path is relative to the root of the site.
      },
    },
    'gatsby-plugin-sass',
    'gatsby-plugin-offline'
  ],
}
