import React from 'react'
import Helmet from 'react-helmet'
import copy from '../assets/copy.json'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'

// import thumb01 from '../assets/images/fulls/vo-00.jpg'
// import thumb02 from '../assets/images/fulls/vo-01.jpg'
// import thumb03 from '../assets/images/fulls/vo-02.jpg'
// import thumb04 from '../assets/images/fulls/vo-03.jpg'
// import thumb05 from '../assets/images/fulls/vi-01.jpg'
// import thumb06 from '../assets/images/fulls/vi-02.jpg'
// import thumb07 from '../assets/images/fulls/vi-03.jpg'
// import thumb08 from '../assets/images/fulls/vi-04.jpg'
// import thumb09 from '../assets/images/fulls/vi-05.jpg'
// import thumb10 from '../assets/images/fulls/vi-06.jpg'
// import thumb11 from '../assets/images/fulls/vi-07.jpg'
// import thumb12 from '../assets/images/fulls/vi-08.jpg'
// import thumb13 from '../assets/images/fulls/hi-00.jpg'
// import thumb14 from '../assets/images/fulls/hi-01.jpg'
// import thumb15 from '../assets/images/fulls/h0-01.jpg'
// import thumb15 from '../assets/images/fulls/ho-02.jpg'
// import thumb6 from '../assets/images/fulls/ho-03.jpg'
// import thumb17 from '../assets/images/fulls/ho-04.jpg'
// import thumb18 from '../assets/images/fulls/hl-00.jpg'
// import thumb19 from '../assets/images/fulls/vl-00.jpg'
// import thumb20 from '../assets/images/fulls/vl-01.jpg'
// import thumb21 from '../assets/images/fulls/vl-02.jpg'
// import thumb22 from '../assets/images/fulls/vl-03.jpg'

import full01 from '../assets/images/fulls/vo-00.jpg'
import full02 from '../assets/images/fulls/vo-01.jpg'
import full03 from '../assets/images/fulls/vo-02.jpg'
import full04 from '../assets/images/fulls/vo-03.jpg'
import full05 from '../assets/images/fulls/vi-01.jpg'
import full06 from '../assets/images/fulls/vi-02.jpg'
import full07 from '../assets/images/fulls/vi-03.jpg'
import full08 from '../assets/images/fulls/vi-04.jpg'
import full09 from '../assets/images/fulls/vi-05.jpg'
import full10 from '../assets/images/fulls/vi-06.jpg'
import full11 from '../assets/images/fulls/vi-07.jpg'
import full12 from '../assets/images/fulls/vi-08.jpg'
//import full13 from '../assets/images/fulls/hi-00.jpg'
// import full14 from '../assets/images/fulls/hi-01.jpg'
import full15 from '../assets/images/fulls/ho-01.jpg'
import full16 from '../assets/images/fulls/ho-02.jpg'
// import full17 from '../assets/images/fulls/ho-03.jpg'
import full18 from '../assets/images/fulls/ho-04.jpg'
import full19 from '../assets/images/fulls/hl-00.jpg'
import full20 from '../assets/images/fulls/vl-03.jpg'
import full21 from '../assets/images/fulls/vl-01.jpg'
import full22 from '../assets/images/fulls/vl-02.jpg'
import full23 from '../assets/images/fulls/vl-00.jpg'

const DEFAULT_IMAGES = [
    { id: '1', source: full01, thumbnail: full01, caption: '', description: ''},
    { id: '2', source: full02, thumbnail: full02, caption: '', description: ''},
    { id: '3', source: full03, thumbnail: full03, caption: '', description: ''},
    { id: '4', source: full04, thumbnail: full04, caption: '', description: ''},
    { id: '5', source: full05, thumbnail: full05, caption: '', description: ''},
    { id: '6', source: full06, thumbnail: full06, caption: '', description: ''},
    { id: '7', source: full07, thumbnail: full07, caption: '', description: ''},
    { id: '8', source: full08, thumbnail: full08, caption: '', description: ''},
    { id: '9', source: full09, thumbnail: full09, caption: '', description: ''},
    { id: '10', source: full10, thumbnail: full10, caption: '', description: ''},
    { id: '11', source: full11, thumbnail: full11, caption: '', description: ''},
    { id: '12', source: full12, thumbnail: full12, caption: '', description: ''},
   // { id: '13', source: full13, thumbnail: full13, caption: '', description: ''},
   // { id: '14', source: full14, thumbnail: full14, caption: '', description: ''},
    { id: '15', source: full15, thumbnail: full15, caption: '', description: ''},
    { id: '16', source: full16, thumbnail: full16, caption: '', description: ''},
   // { id: '17', source: full17, thumbnail: full17, caption: '', description: ''},
    { id: '18', source: full18, thumbnail: full18, caption: '', description: ''},
    { id: '19', source: full19, thumbnail: full19, caption: '', description: ''},
    { id: '20', source: full20, thumbnail: full20, caption: '', description: ''},
    { id: '21', source: full21, thumbnail: full21, caption: '', description: ''},
    { id: '22', source: full22, thumbnail: full22, caption: '', description: ''},
    { id: '23', source: full23, thumbnail: full23, caption: '', description: ''},
];
//console.log('copy: ', copy);
class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Kelsey's Cabin - Northern Wiscosnin Vacation Rental"
        const siteDescription = "We are located on the shores of crystal clear Crane Lake which is one of the natural lakes that now forms the Chippewa Flowage. Our 3 bedroom lakeside unit is your gateway to all of the finest recreational activites that the Hayward area has to offer."

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Come enjoy the peace and quiet, quality fishing, and all other activities that Crane Lake and the Chippewa Flowage has to offer.</h2>
                        </header>
                        <p>We are located on the shores of crystal clear Crane Lake which is one of the natural lakes that now forms the Chippewa Flowage. Our 3 bedroom lakeside unit is your gateway to all of the finest recreational activites that the Hayward area has to offer.</p>
                        <p>Your private dock with lakeside electric is ready for your boat which we will be happy to help you launch. A 14ft aluminum boat is provided with your rental, so if you have a small outboard motor bring it along.</p>
                        <p>Our cabin is fully furnished with the exception of your towels and personal items. One bedroom has a queen size bed, one a full size bed and the third has one full bed. The living room also contains a full size pullout if needed. WI-FI and a smart tv recently added. There is a picnic table and charcoal grill. ( $1000.00 A WK.+ 5.5% SALES TAX. & 4% TOWN OF HUNTER ROOM TAX )</p>
                        <p>E-mail is nice but if all possible we prefer the old fashion way it gives us an opportunity to talk to you directly. We are now accepting reservations for the upcoming season. We can be reached at (715) 462-9510. If we are out on the lake please leave a detailed message and we will return your call.</p>
                        <h2>Overview</h2>
                        <ul className="row details">
                            <li className="6u 12u(xsmall)"><i className="fa fa-home"></i>Cabin: 500 sq.ft.</li>
                            <li className="6u 12u(xsmall)"><i className="fa fa-bed"></i> 3 Bedrooms</li>
                            <li className="6u 12u(xsmall)"><i className="fa fa-bath"></i>1 Bathroom</li>
                            <li className="6u 12u(xsmall)"><i className="fa fa-users"></i> Sleeps 6</li>
                            <li className="6u 12u(xsmall)"><i className="fa fa-star"></i>Min Stay: 4 nights</li>
                        </ul>

                    </section>

                    <section id="two">
                        <h2>Photos</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />

                    </section>

                    <div id="three">
                        {copy.length && (
                            copy.map(({title, items}, index) => (
                                <section>
                                    <h2 key={index} className="feature">{title}</h2>
                                    <ul className="row details">
                                    {items.map((item, i) => (
                                         <li key={i} className="6u 12u$(xsmall)">{item}</li>
                                    ))}
                                    </ul>
                                </section>
                            )))
                        }
                        <section>
                            <h2 className="feature">
                                Cancellation Policy
                            </h2>
                            <p>100% refund if canceled at least 30 days before arrival date. 50% refund if canceled at least 14 days before arrival date.</p>
                        </section>
                    </div>

                    <section id="four">
                        <h2>Get In Touch</h2>
                        <p>Unit available Saturday to Saturday May 2nd thru Oct. 15th .</p>
                        <div className="row">
                            {/* <div className="8u 12u$(small)">
                                <form method="post" action="#">
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                </form>
                                <ul className="actions">
                                    <li><input type="submit" value="Send Message" /></li>
                                </ul>
                            </div>
                            <div className="4u 12u$(small)"> */}
                            <div className="12u">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        11701 W Kelsey Rd.<br />
                                        Hayward, WI 54843<br />
                                        United States
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        (715) 462-9510
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="#">crlake@centurytel.net</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex
